export const linksLeichtathletik = [
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1001411&s=djEtrxEjQ-UU9a0B0shlAXy1iOUgQoDRvQ9Y10n7Z1GiGuI=&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1001416&s=djEtNd9B1SG1NyZ_x-tRb9RUUQHIFFyvaAl4FatZe9P1jT4=&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1001414&s=djEtgDRR9qJURKoWMtD6t1bzjjteHSxU5MCV-eQv_818VSM=&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1001464&s=djEtDbn95s1ZO18syA1okFWsznlRtLl0S5gur_D6rPCInSk=&imageFormat=_1024x1024",
    /*   "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?type=image&inline=true&id=1001411&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?type=image&inline=true&id=1001416&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?type=image&inline=true&id=1001414&imageFormat=_1024x1024",
    "https://leichtathletik.tvlbern.ch/clubdesk/fileservlet?type=image&inline=true&id=1001464&imageFormat=_1024x1024"*/
    /*"http://www.tvl-la.ch/images/banners/Logo_TrophySport.jpg",
    "http://www.tvl-la.ch/images/banners/Logo_Valiant2.png",
    "http://www.tvl-la.ch/images/banners/Logo_Interbroc2.png",
    "http://www.tvl-la.ch/images/banners/Logo_Kchli_320x240.png"*/
]

export const linksVolleyball =
    [
        "https://volleyball.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1000050&s=djEt-3lST13E_K57_CQegr05-ocf6gCzHl8N5uyv-F8jOWQ=&imageFormat=_1024x1024",
        "https://volleyball.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1000051&s=djEtZyUz8OzfuOfN5gmWp0lpwqP8t6FGbzTvNquc_6PZlaU=&imageFormat=_1024x1024",
        "https://volleyball.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1000052&s=djEtin_HhvE7ketuTecxNVsba5G5Qhj6HyMRnSVjgt3Fetk=&imageFormat=_1024x1024",
        "https://volleyball.tvlbern.ch/clubdesk/fileservlet?inline=true&type=image&id=1000049&s=djEtnNnuywIVr60SoWBhD1sL5UNff5QldMgAK2ogZ9fUAzQ=&imageFormat=_1024x1024",
    ]
