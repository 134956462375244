import React from 'react'

import {
    Routes,
    Route,
} from "react-router-dom";

import NewsletterGenerator from "./NewsletterGenerator";
import Overview from "./Overview";
import PictureEditorPage from "./PictureEditorPage";
import ResultsPage from "./ResultsPage";
import Michu from "./Michu";
import Nick from "./Nick" ;

import AppMenu from "../components/menu/AppMenu";
import Luca from "./Luca";

function AppRouter() {
    return (
        <Routes>
            <Route path={"/"} element={<AppRouterLandingPage/>}/>
            <Route path={"*"} element={<AppRouterDefault/>}/>
        </Routes>
    )
}


function AppRouterLandingPage() {
    return (
        <>
            <AppMenu links={[]}/>
            <div >
                <Routes>
                    <Route path="/" element={<ResultsPage/>}/>
                </Routes>
            </div>
        </>
    )
}

function AppRouterDefault() {
    return (
        <>
            <AppMenu links={[
                {label: 'Resultate', path: '/'},
                {label: 'Newsletter', path: '/newsletter'},
                {label: 'Bild Editor', path: '/picture'}
            ]}/>
            <div className={'App'}>
            <Routes>
                <Route path="/" element={<ResultsPage/>}/>
                <Route path="newsletter" element={<NewsletterGenerator/>}/>
                <Route path="newsletter-volleyball" element={<NewsletterGenerator clubArticleSource={"volleyball"} titleExtension={"Volleyball"}/>}/>
                <Route path="picture" element={<PictureEditorPage/>}/>
                <Route path="tools" element={<Overview/>}/>
                <Route path="Michu" element={<Michu/>}/>
                <Route path="Nick" element={<Nick/>}/>
                <Route path="Luca" element={<Luca/>}/>
            </Routes>
            </div>
        </>
    )
}

export default AppRouter
