//let template = require('es6-template-string');
import templateEngine from 'es6-template-string'
import {articleTemplate, base64_tvl_logo, footerTemplate, lineTemplate, mailTemplate} from "./htmlTemplates";
import {linksLeichtathletik, linksVolleyball} from "./adLinks";

abstract class GenericHTMLGenerator{
    abstract getLogo(): string
    abstract getAdLinks(): string[]
    abstract getLinkToViewInBrowser(): string
    abstract getClubName(): string

    generateHTML(mailText: string, articles: Article[]): string{
        const articleHTML = articles.map((article, index) => this.getHTMLOfArticle(article, index))
        const articlesLinesHTML = this.getHTMLofArticleLines(articleHTML)
        const footerHTML = this.getHTMLOfFooter(this.getAdLinks(), this.getClubName())
        const newsletter = this.getHTMLOfMail(articlesLinesHTML, footerHTML, "<span>"+ mailText + "</span>")
        return newsletter

    }

    private getHTMLOfArticle(article: Article, position: number): string{
        const pos = position % 2 === 0 ? 'left' : 'right'
        const template = templateEngine.compile(articleTemplate)

        return template({
            position: pos,
            article_title: article.title,
            article_path: article.link,
            article_picture: article.picturePath,
            article_text:article.detail
        })
    }

    private getHTMLofArticleLines(articleHTMLs: string[], html: string=""): string{
        if(articleHTMLs.length === 0){
            return html;
        }
        if(articleHTMLs.length === 1){
            const newLine = this.addArticlesToLine(articleHTMLs)
            return html + newLine
        }

        const first: string =  articleHTMLs.shift() ?? ""
        const second: string  = articleHTMLs.shift() ?? ""
        const newLine = this.addArticlesToLine([first, second])
        return this.getHTMLofArticleLines(articleHTMLs, html + newLine)
    }

    private addArticlesToLine(articles: string[]){
        const template = templateEngine.compile(lineTemplate)
        return template({articles: articles.join()})
    }

    private getHTMLOfFooter(adLinks: string[], clubName: string){
        const template = templateEngine.compile(footerTemplate)
        return template({
            ad_0: adLinks[0],
            ad_1: adLinks[1],
            ad_2: adLinks[2],
            ad_3: adLinks[3],
            copyright: `${clubName}, ${new Date().getFullYear()}`
        })
    }

    private getHTMLOfMail(articlesAsLinesHTML: string,  footerHTML: string, text: string,): string{
        const template = templateEngine.compile(mailTemplate)
        return template({
            footer: footerHTML,
            articles: articlesAsLinesHTML,
            text: text,
            tvl_logo: this.getLogo(),
            linkToViewInBrowser: this.getLinkToViewInBrowser()
        })
    }
}

class LeichtathletikHTMLGenerator extends GenericHTMLGenerator{
    getLinkToViewInBrowser(): string {
        return "https://leichtathletik.tvlbern.ch/news"
    }
    getLogo(): string {
        return base64_tvl_logo
    }

    getAdLinks(): string[] {
        return linksLeichtathletik
    }

    getClubName(): string{
        return "TVL Leichtathletik"
    }
}

class VolleyballHTMLGenerator extends GenericHTMLGenerator{
    getLinkToViewInBrowser(): string {
        return "https://volleyball.tvlbern.ch/news"
    }
    getLogo(): string {
        return base64_tvl_logo
    }

    getAdLinks(): string[] {
        return linksVolleyball
    }
    getClubName(): string{
        return "TVL Volleyball"
    }

}

const sourceTypeMap: {[key: string]: any} = {
    leichtathletik: LeichtathletikHTMLGenerator,
    volleyball: VolleyballHTMLGenerator
}

function generateHTML(mailText: string, articles: Article[], sourceType?: string){
    let generator = undefined
    if(sourceType){
        generator = sourceTypeMap[sourceType]
    }
    if(!generator){
        // set fallback
        generator = LeichtathletikHTMLGenerator
    }

    return new generator().generateHTML(mailText, articles)
}


export {
    generateHTML
}
