import React, {useEffect, useState} from "react";
import {useArticleFetcher} from "../api/article";
import {generateHTML} from "../services/HTMLGenerator";
import {Button, Accordion, Space, Title, Group, Tooltip, Loader} from "@mantine/core";
import TextInputField from "../components/newsletter/TextInputField";
import ArticleGrid from "../components/newsletter/ArticleGrid";
import PreviewModal from "../components/newsletter/PreviewModal";
import SavedIndicator from "../components/newsletter/SavedIndicator";
import {saveAs} from 'file-saver';

interface NewsletterGeneratorProps {
    clubArticleSource?: string
    titleExtension?: string
}

function NewsletterGenerator({clubArticleSource, titleExtension}: NewsletterGeneratorProps) {
    const [articles, setArticles] = useState<Article[]>([])
    const [selectedArticles, setSelectedArticles] = useState<Set<number>>(new Set())
    const [mailText, setMailText] = useState<string>("")
    const [generatedHTML, setGeneratedHTML] = useState<string>("<h5>empty</h5>")
    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [showClipboardSuccess, setShowClipboardSuccess] = useState<boolean>(false)

    const {getArticles, isLoading, errorMessageProps} = useArticleFetcher(clubArticleSource)

    useEffect(() => {
        updateArticles()
    }, [])

    function updateArticles() {
        getArticles().then(articles => {
            const withId = articles.map((a, index) => {
                a.id = index;
                return a
            })
            setArticles(withId)
        })
    }

    function onSave(text: string) {
        setMailText(text)
        setIsSaved(true)
        onGenerateHTML(text)
    }

    function onArticleSelectionChanged(id: number, isSelected: boolean) {
        if (isSelected) {
            setSelectedArticles(current => current.add(id))
        } else {
            setSelectedArticles(current => {
                current.delete(id)
                return current
            })
        }
        onGenerateHTML(mailText)
    }

    function onCopyToClipboard() {
        navigator.clipboard.writeText(generatedHTML)
            .then(() => {
                setShowClipboardSuccess(true)
                setTimeout(() => setShowClipboardSuccess(false), 1500)
            })
    }

    function onSaveHTMLToFile() {
        saveAs(new File([generatedHTML], "newsletter.html", {type: "text/html;charset=utf-8"}))
    }

    function onGenerateHTML(text: string) {
        const selected = articles.filter(article => selectedArticles.has(article.id))
        const result = generateHTML(text, selected, clubArticleSource)
        setGeneratedHTML(result)
    }

    function getTitleExtension() {
        if (titleExtension) {
            return `(${titleExtension})`
        } else {
            return ''
        }
    }

    return (
        <div className="App">
            <Title style={{marginLeft: 10}}>Newsletter Generator {getTitleExtension()}</Title>

            <Accordion variant="contained" style={{margin: 10}}>
                <Accordion.Item value="editor">
                    <Accordion.Control style={{fontSize: 25}}>
                        <>
                            Text schreiben
                            <SavedIndicator isSaved={isSaved}/>
                        </>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <TextInputField title="Mail Text" setIsSaved={setIsSaved} onSave={onSave}/>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="articles">
                    <Accordion.Control style={{fontSize: 25}}>Artikel auswählen {isLoading && <Loader color={'red'} variant={"dots"}/>}</Accordion.Control>

                    <Accordion.Panel>
                        {isLoading && <div>Artikel werden geladen...</div>}
                        {errorMessageProps && <div>{errorMessageProps.message}</div>}
                        <ArticleGrid content={articles} onUpdate={updateArticles}
                                     onSelectionChanged={onArticleSelectionChanged}/>
                    </Accordion.Panel>

                </Accordion.Item>
            </Accordion>

            <Space h={30}/>

            <Group style={{marginLeft: 10}}>
                <Button className={"Button"} disabled={!isSaved} onClick={() => setShowPreview(true)}>Vorschau</Button>
                <Tooltip color="teal" withArrow label="kopiert!" opened={showClipboardSuccess}>
                    <Button className={"Button"} disabled={!isSaved} onClick={() => onCopyToClipboard()}>in
                        Zwischenablage kopieren</Button>
                </Tooltip>
                <Button className={"Button"} disabled={!isSaved} onClick={() => onSaveHTMLToFile()}>HTML
                    herunterladen</Button>
            </Group>
            <Space h={10}/>
            <PreviewModal opened={showPreview} onClose={setShowPreview} html={generatedHTML}/>

        </div>
    );
}

export default NewsletterGenerator
