import axios from "axios";
import {getEmptyErrorMessageProps, getErrorMessageProps, getValIfTestEnv} from "../services/utils";
import {useState} from "react";

//const baseUrl: string =  "http://127.0.0.1:5001/academic-arcade-358908/us-central1/getNewsArticles" //https://getnewsarticles-q77x4v7yva-uc.a.run.app/" //http://127.0.0.1:5001/tvl-newsletter/us-central1/getNewsArticles"
const baseUrl: string = "https://us-central1-academic-arcade-358908.cloudfunctions.net"

function useArticleFetcher(sourceClub?: string): {getArticles: () => Promise<Article[]>, isLoading: boolean, errorMessageProps: ErrorMessageProps} {
    const [errorMessageProps, setErrorMessageProps] = useState<ErrorMessageProps>(getEmptyErrorMessageProps)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getArticles = async (): Promise<Article[]> => {
        setIsLoading(true)
        setErrorMessageProps(getEmptyErrorMessageProps)
        const response: DataResponse<Article[]> = await fetchArticles(sourceClub)
        if(!response.isSuccess){
            setErrorMessageProps(response.error)
        }
        setIsLoading(false)
        return response.data
    }

    return {getArticles, isLoading, errorMessageProps}
}

/**
 * The SourceClub defines from where the articles should be loaded.
 * Possible values are 'leichtathletik' or 'volleyball'.
 * If no value is specified, the endpoint will use 'leichtathletik' as default
 * @param sourceClub
 */
async function fetchArticles(sourceClub?: string): Promise<DataResponse<Article[]>>{
    const type = sourceClub? `?t=${sourceClub}` : ""
    const url = `${baseUrl}/getNewsArticles${getValIfTestEnv('Test', '')}${type}`
    try {
        const {data} = await axios.get<Article[]>(url)
        return {data: data, isSuccess: true, error: getEmptyErrorMessageProps() }
    }catch (e){
        return {data: [], isSuccess: false, error: getErrorMessageProps("Artikel konnten nicht geladen werden.")}
    }

}


export {
    useArticleFetcher
}
